<template>
  <v-card :disabled="disableBtn" flat class="opt tw-cursor-pointer" @click="goToVetting">
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12.25" r="12" fill="white"/>
      <path d="M17.3334 8.25L10.0001 15.5833L6.66675 12.25" stroke="#008EEF" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round"/>
    </svg>
    <div class="d-flex flex-column">
      <span class="action-title" style="color: #FFFFFF;">GET VETTED</span>
      <span class="action tw-pb-3"
            style="color: #FBFBFB;">{{ disableBtn ? vet_status : 'Find global tech jobs' }}</span>
    </div>
    <UpdateProfileModal :dialog="showModal" @close="showModal=false"/>
  </v-card>

</template>

<script>
import UpdateProfileModal from "@/components/dashboard/components/Vetting/UpdateProfileModal";
import {mapGetters} from "vuex";
import {getVettingStatus} from "@/services/api/APIService";

export default {
  name: "GetVettedButton",
  components: {UpdateProfileModal},
  computed: {
    ...mapGetters('onboarding', ['getPercentageComplete']),
    disableBtn() {
      let msg = this.vet_status
      let status
      if (msg === 'Eligible') {
        status = false
      } else status = true
      return status
    }
  },
  created() {
    this.getStatus()
  },
  data() {
    return {
      showModal: false,
      vet_status: null
    }
  },
  methods: {
    goToVetting() {
      if (this.getPercentageComplete > 70) {
        return this.$router.push({name: 'Vetting'})
      }
      this.showModal = true
    },
    async getStatus() {
      await getVettingStatus().then(res => {
        this.vet_status = res.data.status
      })
    }
  }
}
</script>
<style scoped lang="scss">
.opt {
  width: 290px;
  background: #008EEF;
  border: 1px solid #008EEF;
  border-radius: 4px;
  display: flex;
  padding-top: 13px;
  padding-left: 15px;
  gap: 1.2rem;
  @media (max-width: 1561px) {
    width: auto;
  }

}

.action-title {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #475661;
}

.action {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #9CABB5;
}
</style>