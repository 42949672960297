<template>
  <div class="profileProgressWrapper" v-if="value<100">
    <div class="d-flex">
      <v-progress-circular
          :rotate="360"
          :size="60"
          :width="6"
          :value="value"
          color="blue"
          style="margin-right: 2rem"
      >
        <span class="percent">{{value}}</span>
      </v-progress-circular>
      <div class="d-flex flex-column">
        <span class="percent">Your Profile is {{value}}% complete</span>
        <span class="d-flex align-center next tw-flex-wrap">
          <vue-feather type="arrow-right-circle" style="color: rgba(0, 0, 0, 0.5); margin-right: 0.5rem">
          </vue-feather>Next: {{getNextStep}}</span>
      </div>
    </div>

    <v-btn v-if="value < 100" class="gotoProfileBtn tw-py-10" style="background: #FFFFFF; color: #475661" @click="$router.push({name:'Profile'})">Complete Profile</v-btn>

  </div>

</template>

<script>
import {getDashboardPercentage} from "../../services/api/APIService";

export default {
name: "PercentageProfileCompletion",
  data: ()=>{
  return{
    value: "",
    nextStep : ""
  }
  },
  methods:{
   getPercentage(){
     getDashboardPercentage().then(res =>{
       this.$store.commit('onboarding/set_percentage_complete',res.data.total_percentage)
       this.value = res.data.total_percentage
       this.nextStep = res.data.next_step
     })
   }
  },
  computed:{
   getNextStep(){
     let step = ""
     if (this.nextStep === 'create_resume'){
       step = "Create a specialized profile"
     }
     else if (this.nextStep === 'education'){
       step = "Add your educational details"
     }
     else  if (this.nextStep === 'personal_information'){
       step = 'Complete your personal information'
     }
     else  if (this.nextStep === 'job_preference'){
       step = "Choose your job preference"
     }
     else if (this.nextStep === 'portfolio_and_social_links'){
       step = "Add your social links"
     }
     return step
   }
  },
  created() {
  this.getPercentage()
  }
}
</script>

<style scoped lang="scss">
.profileProgressWrapper{
  background: #F7FCFF;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
  }

}
.percent{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* semicolon-neutral-black */

  color: #2B1C1C;

}
.next{
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: rgba(0, 0, 0, 0.5);

}
.gotoProfileBtn{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  height: 45px !important;
  line-height: 21px;
  text-transform: capitalize;
  @media (max-width: 786px) {
    margin-left: 5rem;
    margin-top: 1rem;
  }


}

</style>
