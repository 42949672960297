<template>
<div>
<v-row class="pt-5 flex-column xl:tw-pr-10" >
  <v-col class="explore-card" v-for="(job_in_location, i) in explore_job_by_location" :key="i"  >
    <div class="explore-action mb-4 tw-flex">
      <div class="company-logo2" @click="handleAction(job_in_location.id,'goto')">
        <img v-if="job_in_location.company_logo" :src="job_in_location.company_logo" style="width: 32px; height: 32px;"/>
        <default-avatar v-else :company-name="job_in_location.company_name" />
      </div>
      <div class="save-icon tw--mt-5">
        <v-btn :loading="job_in_location.loading" text
               @click="handleAction(job_in_location.id,'save')">
          <div class="tw-flex " v-if="!job_in_location.is_saved">
            <vue-feather type="bookmark" size="19"/>
            <span>save</span>
          </div>
          <v-icon v-if="job_in_location.is_saved">mdi-bookmark</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="job-details" @click="handleAction(job_in_location.id,'goto')">
      <span class="job-title eliClass">{{job_in_location.title}}</span>
      <span class="details eliClass">{{job_in_location.company_name}} <span class="bullet">&bull;</span> <span class="job-type">{{job_in_location.type}}</span> </span>
    </div>
    <div class="explore-action pt-8" @click="handleAction(job_in_location.id,'goto')">
      <span v-if="job_in_location.is_remote === true" class="type"><vue-feather type="map-pin" size="18" class="mr-1"/>Remote</span>
      <span v-else class="type"><vue-feather type="map-pin" size="18" class="mr-1"/>On-site</span>
      <span class="type time">{{ processDay(job_in_location.created_at)}} </span>
    </div>
  </v-col>
</v-row>
</div>
</template>

<script>
import {savedJob} from "../../services/api/APIService";
import DefaultAvatar from "../reusables/DefaultAvatar";
import timeDayMixin from "@/mixins/timeDay.mixin";

export default {
  name: "ExploreSideCard",
  mixins:[timeDayMixin],
  components: {DefaultAvatar},
  props: {
    explore_job_by_location: Array
  },
  data(){
    return{
      loading :false,
    }
  },
  // created() {
  //   this.processedDay()
  // },
  computed:{
    addLoadingToJob (){
      return this.explore_job_by_location.map((job) =>({
        ...job,
        loading : false,
        isSaved : false
      }))
    }
  },
  methods: {
    handleAction(job_id,type){
      if (type === "save"){
        this.savedJob(job_id)
      }
      else if (type==="goto") {
        this.goto(job_id)
      }
    },
    goto(jobId){
      this.$router.push({
        name: "SearchResult",
        query: {jobId}
      });
    },
    savedJob(job_id){
      this.$emit('toggleLoading', job_id)
      const userData = JSON.parse(sessionStorage.getItem('userData'))
      let data = {}
      data.job_id = job_id
      data.candidate_email = userData.email
      savedJob(data).then(()=>{
        this.$emit('savingExplore', job_id)
        this.$emit('toggleLoading', job_id)

      })
          .catch(err=> {
            console.log(err)
            let errorKey = Object.keys(err.response.data)
            this.$displaySnackbar({
              message: err.response.data[errorKey[0]],
              success: false
            })
          }).finally(() => {

      })
    },
  }
}
</script>

<style scoped lang="scss">
.eliClass{
  width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.eliClass:hover {
  overflow: visible;
}
.explore-title{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #475661;
  padding: 1rem 0;
  border-bottom: 1px solid #F6F6F6;

}
.explore-card{
  margin: 1rem;
  width: 100%;
  max-width: 328px;
  min-height: 180px;
  background: #FFFFFF;
  border: 1px solid #FBFBFB;
  box-sizing: border-box;
  box-shadow: 2px 2px 8px 1px rgba(0, 142, 239, 0.08);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  cursor: pointer;
}
.explore-action{
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.save-icon{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #9CABB5;

}
.company-logo2{
  width: 40px;
  height: 40px;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.job-details{
  display: flex;
  flex-direction: column;
  .details{
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #475661;
  }
}
.type{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #475661;
  display: flex;


}
.job-title{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #1E323F;

}
//.bullet{
//
//    content: "\2022";
//  color: #000000;
//
//}

</style>


