<template>
  <div>
  <v-row class="job-listings" >
    <v-col class="job-list" v-for="(job, i) in job_preference" :key="i"  >
      <div class="company-details" @click="goto(job.id,job.is_saved)">
        <div class="company-logo">
          <img v-if="job.company_logo" :src="job.company_logo" style="width: 32px; height: 32px;"/>
          <default-avatar v-else :company-name="job.company_name"/>
        </div>
        <div class="d-flex flex-column">
          <span class="job-title">{{job.title}}</span>
          <span class="company">{{job.company_name}} <span class="bullet">&bull;</span> <span class="time-created">{{processedDay(job.date_created)}} </span></span>
        </div>
      </div>
      <div class="job-card" @click="goto(job.id,job.is_saved)">
        <span class="pre-intro"> {{job.company_name}} is hiring a</span>
        <span class="job-advert">{{job.title}}</span>
      </div>
      <div class="icons tw-flex align-center">
        <ShareJobModal class="ml-n10 mr-n10" :button-text="btnText" :logo="job.company_logo||noImage" :id="job.id" :job-title="job.title" :company="job.company_name" :job-type="job.type"/>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="black" v-bind="attrs" v-on="on" icon :loading="job.loading" text @click="savedJob(job.id)">
              <vue-feather v-if="!job.is_saved" type="bookmark" size="20"/>
              <v-icon v-if="job.is_saved" >mdi-bookmark</v-icon>
            </v-btn>
          </template>
          <span v-if="!job.is_saved">Save</span>
          <span v-if="job.is_saved">Saved</span>
        </v-tooltip>
      </div>
    </v-col>
  </v-row>
  </div>


</template>


<script>
import {savedJob} from "../../services/api/APIService";
import DefaultAvatar from "../reusables/DefaultAvatar";
import ShareJobModal from "@/components/dashboard/ShareJobModal";

export default {
name: "JobPreferenceCard",
  components: {ShareJobModal, DefaultAvatar},
  props: {
  job_preference: Array,
    clickSave:[Boolean]
  },
  data(){
  return{
    btnText:'',
    loading : false,
    noImage: 'https://images.unsplash.com/photo-1632169505206-9506f48761e6?ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzfHx8ZW58MHx8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
  }
  },
  // created() {
  // this.processedDay()
  // },
  methods: {
    goto(jobId,isSaved){
      this.$router.push({
        name: "SearchResult",
        query: {jobId,isSaved:isSaved}
      });
    },
    savedJob(job_id){
      this.$emit('loading', job_id)
      const userData = JSON.parse(sessionStorage.getItem('userData'))
      let data = {}
      data.job_id = job_id
      data.candidate_email = userData.email
      savedJob(data).then(()=>{
        this.$emit('saving', job_id)
        this.$emit('loading', job_id)
      })
          .catch(err=> {
            console.log(err)
            let errorKey = Object.keys(err.response.data)
            this.$displaySnackbar({
              message: err.response.data[errorKey[0]],
              success: false
            })
          }).finally(() => {

      })
    },
    processedDay(date_created) {
      let day;
      let date, now
      day = date_created.split("T")[0]
      let d1 = date = new Date(day);
      let d2 = now = new Date();
      if (d2.getTime() < d1.getTime()) {
        d1 = now;
        d2 = date;
      }
      let yd = d1.getYear();
      let yn = d2.getYear();
      let years = yn - yd;
      let md = d1.getMonth();
      let mn = d2.getMonth();
      let months = mn - md;
      if (months < 0) {
        years--;
        months = 12 - md + mn;
      }
      let dd = d1.getDate();
      let dn = d2.getDate();
      let days = dn - dd;
      if (days < 0) {
        months--;
        // figure out how many days there are in the last month
        d2.setMonth(mn, 0);
        days = d2.getDate() - dd + dn;
      }
      let weeks = Math.floor(days / 7);
      days = days % 7;
      if (years > 0) return years + 'year' +  (years > 1 ? "s" : "") + " " + "ago"
      if (months > 0) return months + ' month' +  (months > 1 ? "s" : "") + " " + "ago"
      if (weeks > 0) return weeks + ' week' +  (weeks > 1 ? "s" : "") + " " + "ago"
      if(days > 0) return  days + ' day' +  (days > 1 ? "s" : "")  + " " + "ago" ;
      return "Created Today";

      // return day;

    }
  }
}
</script>

<style scoped lang="scss">
.job-listings{
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.job-list{
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 1.2rem;
  cursor: pointer;

}

.job-card{
  background: #5D18BE;
  border-radius: 16px;

  width: 100%;
  min-height: 280px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (min-width: 1022px) {
    max-width: 520px;
  }

}
.pre-intro{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #F2E9FF;
}
.job-advert{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: #FFFFFF;
}
//.icons{
//  display: flex;
//  gap: 1.5rem;
//
//}
.company-logo{
  width: 60px;
  height: 60px;
img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
}
.job-title{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #000000;
}
.company{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.46);
}
.company-details{
  display: flex;
  gap: 1.2rem;
}

</style>
