<template>
  <div>
    <PercentageProfileCompletion/>
    <div class="feeds tw-pb-14">
      <div class="lg:tw-w-7/12 tw-w-full job-list-wrapper">
        <h3 class="feed-title">Feeds</h3>
        <v-row class="options-wrapper tw-flex-col lg:tw-flex-row">
          <v-col cols="12" md="6" class="options tw-cursor-pointer" @click="gotoSearch">
          <vue-feather type="search" style="color: #008EEF"/>
            <div class="d-flex flex-column">
              <span class="action-title">find jobs</span>
              <span class="action">Explore more jobs</span>
            </div>
          </v-col>
          <GetVettedButton />
        </v-row>
        <Loader v-if="loading"/>
        <JobPreferenceCard @loading="toggleLoadingPreference" @saving="toggleJobStatus" :job_preference="job_preference"/>
      </div>
      <div class="tw-hidden lg:tw-block tw-w-4/12 explore-job-near-you">
       <h3 class="explore-title">
         explore jobs near you
       </h3>
        <div v-if="explore_job_by_location.length > 0">
       <explore-side-card @toggleLoading="toggleLoading" @savingExplore="toggleJobStatus" :explore_job_by_location="explore_job_by_location"/>
        </div>
<!--        <div v-else class="explore-empty-card">-->
<!--          no jobs in your location currently-->
<!--        </div>-->
      </div>

    </div>

  </div>

</template>

<script>
import PercentageProfileCompletion from "@/components/reusables/PercentageProfileCompletion";
import JobPreferenceCard from "@/components/dashboard/JobPreferenceCard";
import {getExploreJobByLocation, getUserJobPreference} from "@/services/api/APIService";
import Loader from "@/Loader";
import {mapGetters} from "vuex";
import ExploreSideCard from "@/components/dashboard/ExploreSideCard";
import {gotoJobPage} from "@/services/utils";
import GetVettedButton from "../reuseables/GetVettedButton";
import {viewFile} from "@/services/utils/aws";


export default {
name: "ApplicantDashboardHome",
  components: {GetVettedButton, Loader, JobPreferenceCard, PercentageProfileCompletion, ExploreSideCard},
  data: () => {
  return{
    loading: false,
    job_preference: [],
    explore_job_by_location: []
  }
  },
  computed: {
    ...mapGetters('applicantProfile',[ 'getApplicantProfile'])
  },


created() {
  this.loading = true
  this.getData()
},
  methods: {
    gotoSearch(){
      gotoJobPage()
    },
    toggleLoading(id) {
      const job = this.explore_job_by_location.find(j => j.id === id)
      job.loading = !job.loading
    },
    toggleLoadingPreference(id){
      const job = this.job_preference.find(j => j.id === id)
      job.loading = !job.loading
    },
    toggleJobStatus(id){
     const jobInPreference = this.job_preference.find(j => j.id === id)
      jobInPreference.is_saved = !jobInPreference.is_saved
      const jobInLocation = this.explore_job_by_location.find(j => j.id === id)
      jobInLocation.is_saved = !jobInLocation.is_saved
    },
   async getData(){

      await this.$store.dispatch('applicantProfile/getApplicant')
      const getState = this.getApplicantProfile.location
      const job_preference = this.getApplicantProfile.job_preference
      const userData = JSON.parse(sessionStorage.getItem('userData'))
      if (userData) {
        const data = {}
        data.job_preference = job_preference
        data.user_email = userData.email
        await getUserJobPreference(data).then(async res => {
          res.data.results.forEach(result =>{
            viewFile({url:result.company_logo,context:result,property:'company_logo'})
            result.loading = false
          })
          this.job_preference = res.data.results
          this.loading = false
          await getExploreJobByLocation(getState, data).then(res => {
            res.data.results.forEach(result =>{
              viewFile({url:result.company_logo,context:result,property:'company_logo'})
              result.loading = false
            })
            this.explore_job_by_location = res.data.results
            this.loading = false
          })
        }).catch(error => {
          console.log(error)

        })
      }
    }
  }

}
</script>

<style scoped lang="scss">
.job-list-wrapper{

}
.job-listings{
  padding-top: 2rem;

}
.feed-title{
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #475661;
  margin-bottom: 2rem;
}
.options{
  border: 1px solid #E0E9FF;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  gap: 1.2rem;
}
.feeds{
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}
.options-wrapper{
  display: flex;
  gap: 2rem;
  padding: 1rem;
}
.action-title{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #475661;
}
.action{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #9CABB5;
}


.explore-job-near-you{
  margin-top: 7rem;
}
.explore-title{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #475661;
  padding: 1rem 0;
  border-bottom: 1px solid #F6F6F6;

}
.explore-empty-card{
  margin: 1rem;
  width: 100%;
  max-width: 328px;
  min-height: 180px;
  background: #FFFFFF;
  border: 1px solid #FBFBFB;
  box-sizing: border-box;
  box-shadow: 2px 2px 8px 1px rgba(0, 142, 239, 0.08);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-family: DM Sans;

  padding: 1rem;
  cursor: pointer;
}



</style>
