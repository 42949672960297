<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="430"
        transition="dialog-top-transition"
        persistent
    >
      <v-card class="tw-p-3 md:tw-p-5 cardBack">
        <div class="tw-flex tw-justify-end">
          <v-btn icon text @click="$emit('close')">
            <v-icon color="black" class="ml-2">mdi-close</v-icon>
          </v-btn>
        </div>

        <div class="inner-content">
          <div class="mb-8">
            <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.4" d="M39.9994 74.2537C58.4088 74.2537 73.3327 59.3299 73.3327 40.9204C73.3327 22.5109 58.4088 7.58704 39.9994 7.58704C21.5899 7.58704 6.66602 22.5109 6.66602 40.9204C6.66602 59.3299 21.5899 74.2537 39.9994 74.2537Z" fill="#EEF8FF"/>
              <path d="M40 24.0204C33.1 24.0204 27.5 29.6204 27.5 36.5204C27.5 43.2871 32.8 48.7871 39.8333 48.9871C39.9333 48.9871 40.0667 48.9871 40.1333 48.9871C40.2 48.9871 40.3 48.9871 40.3667 48.9871C40.4 48.9871 40.4333 48.9871 40.4333 48.9871C47.1667 48.7537 52.4667 43.2871 52.5 36.5204C52.5 29.6204 46.9 24.0204 40 24.0204Z" fill="#45B4FF"/>
              <path d="M62.6004 65.4205C56.6671 70.8871 48.7337 74.2538 40.0004 74.2538C31.2671 74.2538 23.3337 70.8871 17.4004 65.4205C18.2004 62.3871 20.3671 59.6205 23.5337 57.4871C32.6337 51.4205 47.4337 51.4205 56.4671 57.4871C59.6671 59.6205 61.8004 62.3871 62.6004 65.4205Z" fill="#008EEF"/>
            </svg>
          </div>
          <p class="inner-title tw-w-96">{{ text }}</p>
        </div>
        <div class="tw-flex tw-justify-center align-center mb-2 mt-5">
          <Base-button class="mr-4" depressed button-text="Update Profile" width="158" @click="update"></Base-button>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import BaseButton from "@/components/reusables/BaseButton";
// import {inviteForExternalTest} from "@/services/api/APIService";

export default {
  name: "UpdateProfileModal",
  components: {BaseButton},
  props: {
    skill: [Object],
    dialog: {
      type: Boolean,
      default: false
    },
  },
  data: () => {
    return {
      text: "Great! Kindly proceed to update your profile to 70% completion and we'll get you started.",
      // noImage: 'https://images.unsplash.com/photo-1632169505206-9506f48761e6?ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzfHx8ZW58MHx8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',

    }
  },
  methods: {
    update(){
      if (this.$route.name === 'Profile') {
        this.$emit('close')
      }else this.$router.push({name:'Profile'})
    }
    // async sendMail() {
    //   this.loading = true
    //   try {
    //     let skill = {
    //       ...this.skill,
    //       test_id: this.skill.id
    //     }
    //     await inviteForExternalTest(skill)
    //     this.$displaySnackbar({message: 'Invite Sent', success: true})
    //     await this.$router.push({name: 'Vetting'})
    //
    //   } catch (e) {
    //     this.$displaySnackbar({message: e.response.data.message, success: false})
    //   }finally {
    //     this.loading = false
    //   }
    //   this.closeModal()
    // },
    // closeModal() {
    //   this.$emit('close')
    // },
  }
}
</script>

<style scoped lang="scss">
.cardBack {
  width: auto;
  min-height: 291px;
  background: #FFFFFF;
  box-shadow: 0px 24px 32px rgba(0, 61, 103, 0.16), 0px 4px 4px rgba(0, 61, 103, 0.1);
  border-radius: 12px;
}

.inner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "DM Sans", serif;
  line-height: 31px;

}

.main-title {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #475661;
  @media (min-width: 650px) {
    white-space: pre;
  }
}

.inner-title {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #475661;

}
</style>